import { useEffect } from "react";
import { setTitle } from "../helpers/title";

function Agents() {
	useEffect(() => {
		setTitle(`Agents & Brokers - Partner with MyHealthily`);
	}, []);
	
	return (
		<div role="main" className="main">
			
			<section className="page-header page-header-classic page-header-md">
				<div className="container">
					<div className="row">
						<div className="col-md-8 order-2 order-md-1 align-self-center p-static">
							<h1 data-title-border>Agents & Brokers</h1>
						</div>
					</div>
				</div>
			</section>
			
			<div className="container container-xl-custom py-1 my-1">
				<div className="row justify-content-center">
					<div className="col-xl-9 text-center">
						<h2 className="font-weight-bold text-11 appear-animation"
							data-appear-animation="fadeInUpShorter">Chamber Agents Wanted!</h2>
						<p className="line-height-9 text-4 opacity-9 appear-animation"
						   data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">Partner with <a
							href="https://myhealthily.com">MyHealthily</a> to provide affordable healthcare
							coverage to fellow chamber members. Our platform enables you to quote, enroll and renew
							health and ancillary insurance for group with 1-500+ employees, in less than 45-minutes.</p>
					</div>
				</div>
			</div>
			
			<div className="appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
				<div className="home-concept mt-5">
					<div className="container">
						
						<div className="row text-center">
							<span className="sun"></span>
							<span className="cloud"></span>
							<div className="col-lg-2 ms-lg-auto">
								<div className="process-image">
									<img src="img/health.jpg" alt=""/>
									<strong>Health</strong>
								</div>
							</div>
							<div className="col-lg-2">
								<div className="process-image process-image-on-middle">
									<img src="img/ancillary.jpg" alt=""/>
									<strong>Ancillary</strong>
								</div>
							</div>
							<div className="col-lg-2">
								<div className="process-image">
									<img src="img/profitably.jpg" alt=""/>
									<strong>Profitably</strong>
								</div>
							</div>
							<div className="col-lg-4 ms-lg-auto">
								<div className="project-image">
									<div id="fcSlideshow" className="fc-slideshow">
										<ul className="fc-slides">
											<li><a href="https://myhealthily.com/schedule-demo/" aria-label=""><img
												className="img-fluid" src="img/circle-myhealthily.jpg"
												alt="SCHEDULE DEMO"/></a></li>
											<li><a href="https://myhealthily.com/schedule-demo/" aria-label=""><img
												className="img-fluid" src="img/circle-laptop.jpg"
												alt="SCHEDULE DEMO"/></a></li>
										</ul>
									</div>
									<strong className="our-work">MyHealthily</strong>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row pt-3 pb-5 mb-5 appear-animation" data-appear-animation="fadeInUpShorter"
					 data-appear-animation-delay="300">
					<div className="col-lg-8">
						<div className="row">
							<div className="col-sm-6">
								<div className="feature-box feature-box-style-2">
									<div className="feature-box-icon">
										<i className="icons icon-clock text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">One 45-Minute Call</h4>
										<p className="mb-4">With a completed census, turn days and weeks of work into one
											45-minute meeting with your client.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-list text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">More Carriers & Plans</h4>
										<p className="mb-4">Offer your clients thousands of plan options from more than
											135 carriers and ancillary products.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-screen-desktop text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">Easy-to-Use Platform</h4>
										<p className="mb-4">We’ve developed a straight-forward user experience that makes
											enrollment and client management a breeze.</p>
									</div>
								</div>
							</div>
							<div className="col-sm-6">
								<div className="feature-box feature-box-style-2">
									<div className="feature-box-icon">
										<i className="icons icon-layers text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">Full Access</h4>
										<p className="mb-4">Use the only platform that quotes everything- worksite,
											disability, life, dental, vision, and major medical, all in one place.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-emotsmile text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">WOW Customer Service</h4>
										<p className="mb-4">We’re here to help you and your clients resolve issues by
											providing full support from enrollment and beyond.</p>
									</div>
								</div>
								<div className="feature-box feature-box-style-2 mb-3">
									<div className="feature-box-icon">
										<i className="icons icon-target text-color-primary"></i>
									</div>
									<div className="feature-box-info">
										<h4 className="font-weight-bold text-4 mb-0">Marketing Support</h4>
										<p className="mb-4">We provide marketing materials to send to your clients and
											prospects such as email templates, blog articles, and more.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="accordion" id="accordion12">
							<div className="card card-default">
								<div className="card-header" id="collapse12HeadingOne">
									<h4 className="card-title m-0">
										<a className="accordion-toggle" data-bs-toggle="collapse"
										   data-bs-target="#collapse12One" aria-expanded="true"
										   aria-controls="collapse12One">Differentiate Your Agency</a>
									</h4>
								</div>
								<div id="collapse12One" className="collapse show" aria-labelledby="collapse12HeadingOne"
									 data-bs-parent="#accordion12">
									<div className="card-body">
										<p className="mb-0 pb-3">Stand out from your competitors with a technology
											platform that says yes when others say no. Offer a ridiculously simple way to
											quote, enroll, and manage small group benefits profitably.</p>
										<a href="https://myhealthily.com/schedule-demo/"
										   className="btn btn-modern btn-primary btn-effect-1 mt-32">SCHEDULE DEMO</a>
									</div>
								</div>
							</div>
							<div className="card card-default">
								<div className="card-header" id="collapse12HeadingTwo">
									<h4 className="card-title m-0">
										<a className="accordion-toggle" data-bs-toggle="collapse"
										   data-bs-target="#collapse12Two" aria-expanded="false"
										   aria-controls="collapse12Two"> Save 70% of Your Time</a>
									</h4>
								</div>
								<div id="collapse12Two" className="collapse" aria-labelledby="collapse12HeadingTwo"
									 data-bs-parent="#accordion12">
									<div className="card-body">
										<p className="mb-0">From online quoting, to binding, enrollment and services, our
											platform takes the paperwork out of the process. Renewals are a breeze too!
											Write business in one 45-minute call!</p>
									</div>
								</div>
							</div>
							<div className="card card-default">
								<div className="card-header" id="collapse12HeadingThree">
									<h4 className="card-title m-0">
										<a className="accordion-toggle" data-bs-toggle="collapse"
										   data-bs-target="#collapse12Three" aria-expanded="false"
										   aria-controls="collapse12Three">Plans for Everyone</a>
									</h4>
								</div>
								<div id="collapse12Three" className="collapse" aria-labelledby="collapse12HeadingThree"
									 data-bs-parent="#accordion12">
									<div className="card-body">
										<p className="mb-0">Coverage for full-time, part-time, and seasonal employees,
											including dental, vision, LTD, voluntary, hospital, cancer care, and
											more.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row mt-3 mb-5">
					<div className="col-md-4 appear-animation" data-appear-animation="fadeInLeftShorter"
						 data-appear-animation-delay="800">
						<h3 className="font-weight-bold text-4 mb-2">Schedule a Demo</h3>
						<p>Give us 30 minutes to walk you through a demo of our platform, answer questions, and get you
							signed up!</p>
						<a href="https://myhealthily.com/schedule-demo/"
						   className="btn btn-modern btn-primary btn-effect-1 mt-32">SCHEDULE DEMO</a>
					</div>
					<div className="col-md-4 appear-animation" data-appear-animation="fadeIn"
						 data-appear-animation-delay="600">
						<h3 className="font-weight-bold text-4 mb-2">MyHealthily</h3>
						<p>Our platform makes the day-to-day tasks of an agent easier. Our admin team gives your agency
							the support you need.</p>
						<a href="https://myhealthily.com/agents/"
						   className="btn btn-modern btn-primary btn-effect-1 mt-32">LEARN MORE</a>
					</div>
					<div className="col-md-4 appear-animation" data-appear-animation="fadeInRightShorter"
						 data-appear-animation-delay="800">
						<h3 className="font-weight-bold text-4 mb-2">FAQs</h3>
						<p>Get the answers to the most commonly asked questions about MyHealthily’s platform including
							pricing, commissions, and more.</p>
						<a href="https://myhealthily.com/agent-faqs/"
						   className="btn btn-modern btn-primary btn-effect-1 mt-32">VIEW FAQs</a>
					</div>
				</div>
			</div>
			
			<div className="container">
				<div className="row my-3"></div>
				<div className="row my-5 pb-2">
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-ima.jpg" className="img-fluid rounded-circle" alt=""/>
										</div>
										<p><strong className="font-weight-bold">Jodi G.</strong><span>Senior Benefits Account Executive - ima</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Easy to Use Platform</h4>
										<p>The ability to quote and present health and life options to clients on a
											user-friendly platform is a game-changer! The Technology is ideal for us! It
											makes small accounts less cumbersome.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-deland-gibson.jpg" className="img-fluid rounded-circle"
												 alt=""/>
										</div>
										<p><strong className="font-weight-bold">Erin C.</strong><span>Account Executive - Deland, Gibson Insurance</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Super Time Saver</h4>
										<p>Improved efficiency on both the enrollment and renewal sides is easy to see.
											80% of our current clients would appreciate this. You quickly see competitive
											rates, and it’s simple. This platform cuts the amount we spend by at least
											70%! It turns a 7-hour job into a 45-minute call!</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
					<div className="col-lg-4">
						
						<div className="card border-radius-0 bg-color-light border-0 box-shadow-1">
							<div className="card-body py-3 px-2">
								<div className="testimonial testimonial-style-4 border-0 box-shadow-none m-0 p-0">
									<div className="testimonial-author pb-3">
										<div className="testimonial-author-thumbnail">
											<img src="img/logo-insurica.jpg" className="img-fluid rounded-circle"
												 alt=""/>
										</div>
										<p><strong className="font-weight-bold">Gregg G.</strong><span>Virtual Sales Director/Advisor - Insurica</span>
										</p>
									</div>
									<div className="px-4 mx-2 pt-2 pb-3">
										<h4 className="font-weight-semi-bold">Simplified the Process</h4>
										<p>What you’ve done here is taken something that could be hugely complex and time
											consuming and you have shaved 70% – 80% of that time and complexity out of
											it.</p>
										<p className="mb-0"><i className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i><i
											className="fas fa-star text-color-primary text-2"></i></p>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			
			<section className="call-to-action call-to-action-default with-button-arrow call-to-action-in-footer">
				<div className="container">
					<div className="row">
						<div className="col-sm-9 col-lg-9">
							<div className="call-to-action-content">
								<h3>Schedule a Demo</h3>
								<p className="mb-0">Give us a few minutes to walk you through our platform, answer
									questions, and get you signed up!</p>
							</div>
						</div>
						<div className="col-sm-3 col-lg-3">
							<div className="call-to-action-btn">
								<a href="https://myhealthily.com/schedule-demo/"
								   className="btn btn-modern text-2 btn-primary">LET'S TALK</a><span
								className="arrow hlb d-none d-md-block" data-appear-animation="rotateInUpLeft"
								style={{ "left": "110%", top: "-40px" }}></span>
							</div>
						</div>
					</div>
				</div>
			</section>
		
		</div>
	);
}

export default Agents;
