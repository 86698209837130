class WhitelabelModel {
	name = "MyHealthily";
	logo = "";
	assocLogo = "";
	domain = "myhealthily.com";
	theme = "";
	label = "";
	
	setDomain(name) {
		this.domain = name;
		return this;
	}
	
	setName(name) {
		this.name = name;
		return this;
	}
	
	setLogo(name) {
		this.logo = `logo/${name}`;
		return this;
	}
	
	setLabel(name) {
		this.label = `${name}`;
		return this;
	}
	
	setAssocLogo(name) {
		this.assocLogo = `assoc-logo/${name}`;
		return this;
	}
	
	setTheme(name) {
		this.theme = name;
		return this;
	}
}


export function initWhiteLabel() {
	return new WhitelabelModel();
}
